import React, { useState }from 'react'
import '../styles/calculator.css'

export default function Calculator() {
    const [ numErrorVisible, setNumErrorVisible ] = useState(false)
    const [ amountErrorVisible, setAmountErrorVisible ] = useState(false)
    const [ unitsVal, setUnitsVal ] = useState('')
    const [ sliderVal, setSliderVal ] = useState(0)
    const [ sliderInvisible, setSliderInvisible ] = useState(false)
    const [ activeCheckboxes, setActiveCheckboxes ] = useState({ 'RI': false, 'DA': false, 'RR': false })
    const [ result, setResult ] = useState('0')

    const onSliderChange = (e) => {
        setSliderVal(e.target.value)
    }

    const onUnitsChange = (e) => {
        setUnitsVal(e.target.value)
    }

    const toggleCheckbox = (e) => {
        const key = e.target.name
        if (key === 'RI') {
            setActiveCheckboxes({ ...activeCheckboxes, 'RI': !activeCheckboxes['RI']})
        } else if (key === 'DA') {
            setActiveCheckboxes({ ...activeCheckboxes, 'DA': !activeCheckboxes['DA']})
        } else if (key === 'RR') {
            setActiveCheckboxes({ ...activeCheckboxes, 'RR': !activeCheckboxes['RR']})
        }
    }

    const filterEntry = (entry) => {
        let filteredEntry = ''
        for (let i = 0; i < entry.length; i++) {
            if (entry[i] === ',') {
                null
            } else if (entry[i] === '.') {
                break
            } else if (!parseInt(entry[i]) && entry[i] !== '0') {
                null
            } else {
                filteredEntry += entry[i]
            }
        }
        return filteredEntry
    }

    const buildOutput = (num) => {
        const numStr = num.toString()
        let output = ''
        for (let i = 0; i < numStr.length; i++) {
            if (i === (numStr.length - 7) || i === (numStr.length - 4)) {
                output += `${numStr[i]},`
            } else {
                output += numStr[i]
            }
        }
        return output
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const units = filterEntry(e.target[0].value)
        setUnitsVal(buildOutput(units))
        if (parseInt(units)) {
            if (parseInt(units) > 100000) {
                setUnitsVal('100,000')
                setResult('0')
                setAmountErrorVisible(true)
                setNumErrorVisible(false)
            } else {
                const RI = e.target[1].value
                const DA = e.target[2].value
                const RR = e.target[3].value
                const rate = (e.target[4].value) / 100
                const RICalc = (parseInt(units) * RI)
                const DACalc = (parseInt(units) * rate * DA * 12)
                const RRCalc = (parseInt(units) * rate * RR * 12)
                const res = RICalc + DACalc + RRCalc
                setAmountErrorVisible(false)
                setNumErrorVisible(false)
                setResult(buildOutput(Math.round(res)))
            }
        } else {
            setResult('0')
            setNumErrorVisible(true)
            setAmountErrorVisible(false)
        }

    }

    return (
        <div className='grey-background standard-component-spacing'>
            <div className='calculator'>
                <div className='left-section'>
                    <h1>Resident Services Yield Calculator</h1>
                    <p>Add revenue streams that require almost no onsite lift with Entrata’s resident services. See how much you can make with Renters Insurance, Rent Reporting, or Deposit Alternative.</p>
                </div>
                <div className='right-section'>
                    <div className='input-container'>
                        <form onSubmit={onSubmit}>
                            <div className='units-selection'>
                                <div>
                                    <label htmlFor='units'><p>Number of Units</p></label>
                                    <div className='tooltip'>
                                        <div>i</div>
                                        <span className='tooltip-text'><p>Enter the total amount of units owned or managed.</p></span>
                                    </div>
                                </div>
                                <div className='enter-units-container'>
                                    <input 
                                        className='enter-units'
                                        name='units' 
                                        type='text'
                                        value={unitsVal}
                                        onChange={onUnitsChange}
                                    />
                                    <span key={Math.random()} id='units-warning-amount' style={amountErrorVisible ? {visibility: 'visible'} : {visibility: 'hidden'}}><p>Maximum number of units accepted is 100,000</p></span>
                                    <span key={Math.random()} id='units-warning-numtype' style={numErrorVisible ? {visibility: 'visible'} : {visibility: 'hidden'}}><p>Please enter a valid number (e.g., 25,000) </p></span>
                                </div>
                            </div>
                            <div className='product-selection'>
                                <div>
                                    <label htmlFor='select-product'><p>Homebody Product</p></label>
                                    <div className='tooltip'>
                                        <div>i</div>
                                        <span className='tooltip-text'><p>Select any number of Homebody products.</p></span>
                                    </div>
                                </div>
                                <div id='select-product' className='checkboxes'>
                                    <div className='checkbox'>
                                        <label htmlFor='RI'><p>Renters<br></br>Insurance</p></label>
                                        <input 
                                            type='checkbox'
                                            value={ activeCheckboxes['RI'] ? 6 : 0 }
                                            name='RI'
                                            onChange={toggleCheckbox}
                                        />
                                    </div>
                                    <div className='checkbox'>
                                        <label htmlFor='DA'><p>Deposit<br></br>Alternative</p></label>
                                        <input 
                                            type='checkbox'
                                            value={ activeCheckboxes['DA'] ? 4.17 : 0 }
                                            name='DA'
                                            onChange={toggleCheckbox}
                                        />
                                    </div>
                                    <div className='checkbox'>
                                        <label htmlFor='RR'><p>Rent<br></br>Reporting</p></label>
                                        <input 
                                            type='checkbox'
                                            value={ activeCheckboxes['RR'] ? 3 : 0 }
                                            name='RR'
                                            onChange={toggleCheckbox}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='rate-selection' style={(activeCheckboxes['RI'] && !activeCheckboxes['DA'] && !activeCheckboxes['RR']) ? {visibility: 'hidden'} : {visibility: 'visible'}}>
                                <div>
                                    <label htmlFor='select-rate'><p>Adoption Rate</p></label>
                                    <div className='tooltip'>
                                        <div>i</div>
                                        <span className='tooltip-text'><p>Entrata clients typically see adoption rates of about 50-70% for Deposit Alternative, and 70% for Rent Reporting.</p></span>
                                    </div>
                                </div>
                                <div className='slider-bar'>
                                    <input 
                                        id='rate-slider' 
                                        type='range' 
                                        name='select-rate' 
                                        step='5' 
                                        min='0'
                                        max='70'
                                        list='markers' 
                                        value={sliderVal} 
                                        onChange={onSliderChange}
                                    />
                                    <datalist id='markers'>
                                        <option value='0'></option>
                                        <option value='10'></option>
                                        <option value='20'></option>
                                        <option value='30'></option>
                                        <option value='40'></option>
                                        <option value='50'></option>
                                        <option value='60'></option>
                                        <option value='70'></option>
                                    </datalist>
                                    <p>{`${sliderVal}%`}</p>
                                </div>
                            </div>
                            <div className='submit'>
                                <input type='submit' value='Calculate' />
                            </div>
                        </form>
                    </div>
                    <div>
                        <div className='results-container'>
                            <div>
                                <p>Yearly Ancillary Revenue:</p>
                                <div className='tooltip'>
                                    <div>i</div>
                                    <span id='revenue-tooltip-text' className='tooltip-text'><p>Calculations: Renters Insurance = # of units * admin fee; Deposit Alternative & Rent Reporting = # of units * adoption rate * admin fee * 12</p></span>
                                </div>
                            </div>
                            <h2>{`$${result}`}</h2>
                        </div>
                        <p className='help-text'>* The calculator uses the following average admin fees to calculate ancillary revenue: Renters Insurance $6, Deposit Alternative: $4.17, Rent Reporting: $3</p>
                    </div>
                </div>
            </div>
        </div>
    )
}