import React from 'react'
import Layout from '../components/layout'
import Calculator from '../components/calculator'
import LogoSlider from '../components/client-feature'
import { useStaticQuery, graphql } from 'gatsby'


export default function AncillaryYieldCalculator() {
    const logoSliderData = useStaticQuery(graphql`
        query sliderQuery {
            contentfulPortfolioCarousel(title: {eq: "G2 Awards Carousel - leading out on redefining property managment"}) {
                id
                bodyText {
                  bodyText
                }
                logoCarouselHeadline: headlineText {
                  headlineText
                }
                carouselCards {
                  id
                  clientInfo
                  clientLogo {
                    image {
                      gatsbyImageData(width: 500, placeholder: BLURRED)
                      url
                      description
                    }
                  }
                  wistiaVideoId
                  linkToPageSlug
                  linkToPageUrl
                }
            }
        }
    
    `)
    return (
        <Layout>
            <Calculator />
            <LogoSlider layoutPiece={logoSliderData.contentfulPortfolioCarousel} />
        </Layout>
    )
}